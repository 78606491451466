import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"


const Page = () => (
  <Layout>
    <SEO title="About Us"/>
    <main>
      <section id="about">
        <div className="container">
          <div className=" t2-padding">
            <h1>We are building and growing a professional, ethical and low-cost image annotation service.</h1>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12 t-padding b-padding">
              <div className="row">
                <div className="col-md-3"><h2>Vision</h2></div>
                <div className="col-md-9">
                  <q>Increase people’s agency through fair micro-jobs</q>
                  <p>Our core value is ensuring equal opportunity for everyone to develop themselves. A lot of people
                    live under circumstances where they have very little control over their own lives due to economic
                    hardship. We aim to increase their agency by providing them quality jobs with fair pay that take
                    away the limitations that limit them.</p>
                </div>
              </div>
            </div>

            <div className="col-md-12 t-padding b-padding">
              <div className="row">
                <div className="col-md-3"><h2>Our Goal</h2></div>
                <div className="col-md-9">
		  <p>We’re building Humainly as a non-profit social business. That means we want to create a meaningful 
		     impact, and believe that’s best achieved by aligning social needs with economic driving forces. 
		     No handouts, no gifts. We all have to earn it. But people deserve opportunities, and that’s what 
		     we do. We connect the need for image annotation in the west with the need for quality, fairly 
		     paying jobs in poor areas of the world. And the only way to do it successfully is by providing 
		     our customers with the absolute best service they can possibly find.</p>
                </div>
              </div>
            </div>

            <div className="col-md-12 t-padding b-padding">
              <div className="row">
                <div className="col-md-3"><h2>History</h2></div>
                <div className="col-md-9">
                  <p>We’re part of the original founding members behind the Computer Vision consulting firm <a href="https://webmonks.vision/">WebMonks</a>.
                    Our aim was to create social impact in this world
                    for people in poverty stricken areas of the world. We decided we wanted to create fair jobs for
                    people in Venezuela. So what better way to do that than through digital jobs that serve the same
                    market for the same technology we know so well?</p>
                </div>
              </div>
            </div>

            <div className="col-md-12 t-padding b-padding team">
              <div className="row">
                <div className="col-md-3"><h2>The Team</h2></div>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-4 pb-5">
                      <div className="text-center">
                        <img src={'/images/about/jonatan.jpg'} alt="Jonatan Snyders" className="mx-auto"/>
                        <h4 className="mt-3">Jonatan Snyders</h4>
                        <span>CEO</span>
                      </div>
                    </div>
                    <div className="col-md-4 pb-5">
                      <div className="text-center">
                        <img src={'/images/about/gosia.jpg'} alt="Malgorzata Glogowska"/>
                        <h4 className="mt-3">Gosia Glogowska</h4>
                        <span>Project Manager &amp; Marketing </span>
                      </div>
                    </div>
                    <div className="col-md-4 pb-5">
                      <div className="text-center">
                        <img src={'/images/about/maarten.jpg'} alt="Maarten Huijsmans"/>
                        <h4 className="mt-3">Maarten Huijsmans</h4>
                        <span>Lead Software Engineer</span>
                      </div>
                    </div>
                    <div className="col-md-4 pb-5">
                      <div className="text-center">
                        <img src={'/images/about/luis.png'} alt="Luis Arenas"/>
                        <h4 className="mt-3">Luis Arenas</h4>
                        <span>Account Manager &amp; Annotation Expert</span>
                      </div>
                    </div>
                    <div className="col-md-4 pb-5">
                      <div className="text-center">
                        <img src={'/images/about/simon.png'} alt="Simon Snyders"/>
                        <h4 className="mt-3">Simon Snyders</h4>
                        <span>Data Scientist</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </Layout>
)

export default Page
